var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"ma-2"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.protocol),expression:"protocol"}],staticClass:"text-xs-left",attrs:{"xs12":""}},[_c('div',{staticClass:"caption grey--text text-uppercase",staticStyle:{"margin-bottom":"5px"}},[_vm._v(" Vendor ")]),_c('div',[_c('span',{staticClass:"headline font-weight-black",domProps:{"textContent":_vm._s(_vm.vendor || '—')}}),(_vm.vendor)?_c('strong'):_vm._e()])]),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.protocol),expression:"protocol"}],staticClass:"text-xs-left",attrs:{"xs12":""}},[_c('div',{staticClass:"caption grey--text text-uppercase",staticStyle:{"margin-bottom":"5px","margin-top":"10px"}},[_vm._v(" Protocol ")]),_c('div',[_c('span',{staticClass:"headline font-weight-black",domProps:{"textContent":_vm._s(_vm.protocol || '—')}}),(_vm.protocol)?_c('strong'):_vm._e()])]),_c('v-flex',{staticClass:"pt-0"},[_c('v-layout',{staticClass:"pa-0",attrs:{"row":"","wrap":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.protocol || _vm.type == 'camera'),expression:"protocol || type == 'camera'"}],staticClass:"text-xs-center align-self-center",attrs:{"xs4":""}},[(_vm.direction)?_c('div',[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v(" "+_vm._s(_vm.getCardinal(_vm.direction))+" ")]),_c('span',{attrs:{"color":"white"}},[_c('v-icon',{staticClass:"mt-1",style:(("transform: rotate(" + _vm.direction + "deg)"))},[_vm._v("arrow_upward")])],1)]):_vm._e()]),_c('v-flex',{staticClass:"text-xs-left align-self-center",attrs:{"xs8":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',{class:("" + (_vm.hoveredOnCoordinates ? '' : 'transparent')),attrs:{"flat":""},on:{"mouseover":function($event){_vm.hoveredOnCoordinates = true},"mouseleave":function($event){_vm.hoveredOnCoordinates = false}}},[_c('v-card-text',[_c('div',_vm._g({ref:"coordinates",attrs:{"id":"coordinates"},on:{"click":_vm.copyCoordinates}},on),[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v(" COORDINATES ")]),(_vm.mgrsEnabled)?_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.mgrsLocation)+" ")]):_vm._e(),(!_vm.mgrsEnabled && _vm.latlngData.lat)?_c('div',{staticClass:"caption",attrs:{"data-testid":"latitude"}},[_vm._v(" "+_vm._s(("Latitude: " + (_vm.latlngData.lat.toFixed(8))))+" ")]):_vm._e(),(!_vm.mgrsEnabled && _vm.latlngData.lng)?_c('div',{staticClass:"caption",attrs:{"data-testid":"longitude"}},[_vm._v(" "+_vm._s(("Longitude: " + (_vm.latlngData.lng.toFixed(8))))+" ")]):_vm._e()])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCopiedMessage),expression:"showCopiedMessage"}],staticClass:"primary--text font-weight-bold"},[_vm._v(" Coordinates copied ")])]}}])},[_c('span',[_vm._v("Click to copy coordinates")])])],1)],1)],1)],1),(_vm.speed || _vm.type === 'radar')?_c('v-layout',{staticClass:"pb-3",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("Speed")]),_c('div',{attrs:{"data-testid":"speed"}},[_c('span',{class:_vm.infoClass(_vm.speed),domProps:{"textContent":_vm._s(
              _vm.textSpeed
                ? _vm.convertValIfImperial(_vm.systemSetting, _vm.textSpeed, 'km')
                : '-'
            )}}),(_vm.speed)?_c('strong',[_vm._v(_vm._s(_vm.convertUnitIfImperial(_vm.systemSetting, 'km'))+"/h")]):_vm._e()])]),_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("Distance")]),_c('div',{attrs:{"data-testid":"distance"}},[_c('span',{class:_vm.infoClass(_vm.distance),domProps:{"textContent":_vm._s(
              _vm.distance
                ? _vm.convertValIfImperial(_vm.systemSetting, _vm.distance, 'm')
                : '—'
            )}}),(_vm.distance)?_c('strong',[_vm._v(_vm._s(_vm.convertUnitIfImperial(_vm.systemSetting, 'm')))]):_vm._e()])]),_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("Altitude")]),_c('div',{attrs:{"data-testid":"altitude"}},[_c('span',{class:_vm.infoClass(_vm.altitude),domProps:{"textContent":_vm._s(
              _vm.altitude
                ? _vm.convertValIfImperial(_vm.systemSetting, _vm.altitude, 'm')
                : '—'
            )}}),(_vm.altitude)?_c('strong',[_vm._v(_vm._s(_vm.convertUnitIfImperial(_vm.systemSetting, 'm')))]):_vm._e()])]),(_vm.forMaskMap)?_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("Elevation")]),_c('div',{attrs:{"data-testid":"elevation"}},[_c('span',{class:_vm.infoClass(_vm.elevation),domProps:{"textContent":_vm._s((_vm.elevation || 0).toFixed(1))}}),(_vm.elevation)?_c('strong',[_vm._v("°")]):_vm._e()])]):_vm._e(),(_vm.forMaskMap)?_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("RCS")]),_c('div',{attrs:{"data-testid":"rcs"}},[_c('span',{class:_vm.infoClass(true),domProps:{"textContent":_vm._s((_vm.rcs || 0).toFixed(2))}})])]):_vm._e(),(_vm.forMaskMap)?_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs4":""}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v("Confidence")]),_c('div',{attrs:{"data-testid":"confidence"}},[_c('span',{class:_vm.infoClass(_vm.confidence),domProps:{"textContent":_vm._s(_vm.confidence)}})])]):_vm._e()],1):_vm._e(),(_vm.trackable && !_vm.isRadarMaskEditing)?_c('v-btn',{staticStyle:{"opacity":"0.9","bottom":"-12px","right":"-12px"},attrs:{"icon":"","color":"red","absolute":"","right":""},on:{"click":function($event){$event.stopPropagation();return _vm.trackTarget($event)}}},[(_vm.shouldUnTrack)?_c('v-icon',[_vm._v("gps_off")]):_c('v-icon',[_vm._v("gps_fixed")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }