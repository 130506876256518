<template>
  <v-layout row>
    <v-flex class="ma-2">
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-left" v-show="protocol">
          <div
            class="caption grey--text text-uppercase"
            style="margin-bottom: 5px"
          >
            Vendor
          </div>
          <div>
            <span
              class="headline font-weight-black"
              v-text="vendor || '—'"
            ></span>
            <strong v-if="vendor"></strong>
          </div>
        </v-flex>
        <v-flex xs12 class="text-xs-left" v-show="protocol">
          <div
            class="caption grey--text text-uppercase"
            style="margin-bottom: 5px; margin-top: 10px"
          >
            Protocol
          </div>
          <div>
            <span
              class="headline font-weight-black"
              v-text="protocol || '—'"
            ></span>
            <strong v-if="protocol"></strong>
          </div>
        </v-flex>
        <v-flex class="pt-0">
          <v-layout row wrap class="pa-0">
            <v-flex
              xs4
              class="text-xs-center align-self-center"
              v-show="protocol || type == 'camera'"
            >
              <div v-if="direction">
                <div class="caption grey--text text-uppercase">
                  {{ getCardinal(direction) }}
                </div>
                <span color="white">
                  <v-icon
                    class="mt-1"
                    :style="`transform: rotate(${direction}deg)`"
                    >arrow_upward</v-icon
                  >
                </span>
              </div>
            </v-flex>
            <v-flex xs8 class="text-xs-left align-self-center">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-card
                    flat
                    :class="`${hoveredOnCoordinates ? '' : 'transparent'}`"
                    @mouseover="hoveredOnCoordinates = true"
                    @mouseleave="hoveredOnCoordinates = false"
                  >
                    <v-card-text>
                      <div
                        id="coordinates"
                        ref="coordinates"
                        @click="copyCoordinates"
                        v-on="on"
                      >
                        <div class="caption grey--text text-uppercase">
                          COORDINATES
                        </div>
                        <div class="caption" v-if="mgrsEnabled">
                          {{ mgrsLocation }}
                        </div>
                        <div
                          class="caption"
                          v-if="!mgrsEnabled && latlngData.lat"
                          data-testid="latitude"
                        >
                          {{ `Latitude: ${latlngData.lat.toFixed(8)}` }}
                        </div>
                        <div
                          class="caption"
                          v-if="!mgrsEnabled && latlngData.lng"
                          data-testid="longitude"
                        >
                          {{ `Longitude: ${latlngData.lng.toFixed(8)}` }}
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <div
                    v-show="showCopiedMessage"
                    class="primary--text font-weight-bold"
                  >
                    Coordinates copied
                  </div>
                </template>
                <span>Click to copy coordinates</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="speed || type === 'radar'" class="pb-3">
        <v-flex xs4 class="text-xs-center">
          <div class="caption grey--text text-uppercase">Speed</div>
          <div data-testid="speed">
            <span
              :class="infoClass(speed)"
              v-text="
                textSpeed
                  ? convertValIfImperial(systemSetting, textSpeed, 'km')
                  : '-'
              "
            ></span>
            <strong v-if="speed"
              >{{ convertUnitIfImperial(systemSetting, 'km') }}/h</strong
            >
          </div>
        </v-flex>
        <v-flex xs4 class="text-xs-center">
          <div class="caption grey--text text-uppercase">Distance</div>
          <div data-testid="distance">
            <span
              :class="infoClass(distance)"
              v-text="
                distance
                  ? convertValIfImperial(systemSetting, distance, 'm')
                  : '—'
              "
            ></span>
            <strong v-if="distance">{{
              convertUnitIfImperial(systemSetting, 'm')
            }}</strong>
          </div>
        </v-flex>
        <v-flex xs4 class="text-xs-center">
          <div class="caption grey--text text-uppercase">Altitude</div>
          <div data-testid="altitude">
            <span
              :class="infoClass(altitude)"
              v-text="
                altitude
                  ? convertValIfImperial(systemSetting, altitude, 'm')
                  : '—'
              "
            ></span>
            <strong v-if="altitude">{{
              convertUnitIfImperial(systemSetting, 'm')
            }}</strong>
          </div>
        </v-flex>
        <v-flex xs4 class="text-xs-center" v-if="forMaskMap">
          <div class="caption grey--text text-uppercase">Elevation</div>
          <div data-testid="elevation">
            <span
              :class="infoClass(elevation)"
              v-text="(elevation || 0).toFixed(1)"
            ></span>
            <strong v-if="elevation">&deg;</strong>
          </div>
        </v-flex>
        <v-flex xs4 class="text-xs-center" v-if="forMaskMap">
          <div class="caption grey--text text-uppercase">RCS</div>
          <div data-testid="rcs">
            <span
              :class="infoClass(true)"
              v-text="(rcs || 0).toFixed(2)"
            ></span>
          </div>
        </v-flex>
        <v-flex xs4 class="text-xs-center" v-if="forMaskMap">
          <div class="caption grey--text text-uppercase">Confidence</div>
          <div data-testid="confidence">
            <span :class="infoClass(confidence)" v-text="confidence"></span>
          </div>
        </v-flex>
      </v-layout>
      <v-btn
        v-if="trackable && !isRadarMaskEditing"
        icon
        color="red"
        style="opacity: 0.9; bottom: -12px; right: -12px"
        absolute
        right
        @click.stop="trackTarget"
      >
        <v-icon v-if="shouldUnTrack">gps_off</v-icon>
        <v-icon v-else>gps_fixed</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import { latLngToMgrs } from '@/utils/utils.js'

export default {
  name: 'DetectionPopup',
  props: {
    speed: {
      type: [Number, String],
      default: 0
    },
    distance: {
      type: [Number, String],
      default: 0
    },
    elevation: {
      type: [Number, String],
      default: 0
    },
    altitude: {
      type: [Number, String],
      default: 0
    },
    direction: {
      type: [Number, String],
      default: 0
    },
    eta: {
      type: [Number, String],
      default: 0
    },
    confidence: {
      type: [Number, String],
      default: 0
    },
    rcs: {
      type: [Number, String],
      default: 0
    },
    protocol: {
      type: [Number, String]
    },
    vendor: {
      type: String
    },
    classification: {
      type: String
    },
    trackingButton: {
      type: Boolean,
      default: false
    },
    latitude: {
      type: Number,
      default: null
    },
    longitude: {
      type: Number,
      default: null
    },
    track: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'radar'
    },
    forMaskMap: {
      type: Boolean,
      default: false
    },
    adjustSpeed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showCopiedMessage: false,
      hoveredOnCoordinates: false
    }
  },
  computed: {
    ...mapState('radar_mask', { radarMaskEditing: 'editing' }),
    ...mapGetters('cameras', ['allCameras', 'cameraTrackedTarget']),
    ...mapGetters('system', ['systemSetting']),
    ...mapState('sensors', ['activeKey']),
    ...mapState(['mgrsEnabled']),
    mgrsLocation() {
      return latLngToMgrs(this.latlngData.lat, this.latlngData.lng, true)
    },
    isRadarMaskEditing() {
      return this.radarMaskEditing
    },
    latlngData() {
      if (this.longitude && this.longitude) {
        return {
          lat: this.latitude,
          lng: this.longitude
        }
      } else {
        return {
          lat: this.$attrs.start_latitude,
          lng: this.$attrs.start_longitude
        }
      }
    },
    mgrs() {
      return mgrs
    },
    textSpeed() {
      let speed = this.speed || '-'
      if (this.adjustSpeed) {
        speed = (this.speed * 3.6).toFixed(0)
      }
      return speed
    },
    infoClass: () => v => {
      return v ? 'headline font-weight-black' : 'grey--text'
    },
    trackable() {
      return (
        (this.type.includes('radar') || this.type.includes('rf')) &&
        this.trackingButton &&
        this.allCameras.length != 0 &&
        this.$janus.isConnected
      )
    },
    shouldUnTrack() {
      return this.cameraTrackedTarget == this.activeKey
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    //returns mgrs or lat/long based on settings
    getCoordinates(lat, long) {
      if (this.mgrsEnabled) return latLngToMgrs(lat, long)
      return `${lat.toFixed(5)} ${long.toFixed(5)}`
    },
    async copyCoordinates() {
      try {
        let textToCopy = `${this.latlngData.lat.toFixed(
          8
        )}, ${this.latlngData.lng.toFixed(8)}`
        if (this.mgrsEnabled) {
          textToCopy = latLngToMgrs(this.latitude, this.longitude, true)
        }
        await navigator.clipboard.writeText(textToCopy)
        this.showCopiedMessage = true
        setTimeout(() => {
          this.showCopiedMessage = false
        }, 3000)
      } catch (e) {
        console.log(e)
      }
    },
    getCardinal(angle) {
      const degree = 360 / 8
      angle = angle + degree / 2

      if (angle >= 0 * degree && angle < 1 * degree) return 'N'
      if (angle >= 1 * degree && angle < 2 * degree) return 'NE'
      if (angle >= 2 * degree && angle < 3 * degree) return 'E'
      if (angle >= 3 * degree && angle < 4 * degree) return 'SE'
      if (angle >= 4 * degree && angle < 5 * degree) return 'S'
      if (angle >= 5 * degree && angle < 6 * degree) return 'SW'
      if (angle >= 6 * degree && angle < 7 * degree) return 'W'
      if (angle >= 7 * degree && angle < 8 * degree) return 'NW'
      //Should never happen:
      return 'N'
    },
    async trackTarget() {
      this.$bus.$emit(
        'trackTarget',
        this.type.includes('radar') ? 'radar' : 'rf'
      )
    }
  }
}
</script>

<style>
.transparent {
  /* background-color: white !important;
  opacity: 0;
  border-color: transparent !important; */
}

#coordinates:hover {
  cursor: pointer;
}
</style>
